export enum Role {
  OWNER = 1,
  MEMBER = 2,
}

export enum FactoryRole {
  SUPER_ADMIN = "Factory Superadmin",
  ADMIN = "Factory Admin",
  MEMBER = "Factory Member",
  NONE = "",
}
