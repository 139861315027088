export enum NotificationCode {
  IncomingRecord = "incoming_record",
  Overrated = "machine_measurement_results_overrated",
  Underrated = "machine_measurement_results_underrated",
  Measurement = "machine_measurement",
  Maintenance = "machine_maintenance",
  PaymentNotice = "payment_notice",
  AccountExpiring = "account_expiring",
  Other = "other",
}
