import Loadable from "react-loadable";
import Loading from "./components/loading";

const LoadableHome = Loadable({
  loader: () => import("./pages/homePage/homePage"),
  loading: Loading,
});
const LoadableDashboard = Loadable({
  loader: () => import("./pages/homePage/homePage"),
  loading: Loading,
});
const LoadableLogin = Loadable({
  loader: () => import("./pages/loginPage/loginPage"),
  loading: Loading,
});
const LoadableModal = Loadable({
  loader: () => import("react-bootstrap/Modal"),
  loading: Loading,
});

const LoadableOrganization = Loadable({
  loader: () => import("./pages/organizationPage/organizationPage"),
  loading: Loading,
});

const LoadableRegister = Loadable({
  loader: () => import("./pages/registerPage/registerPage"),
  loading: Loading,
});

const LoadableRegisterEmailVerify = Loadable({
  loader: () => import("./pages/registerPage/registerEmailVerify"),
  loading: Loading,
});
const LoadableFirebaseAuth = Loadable({
  loader: () => import("./pages/firebaseAuth/firebaseAuth"),
  loading: Loading,
});

const LoadableRegisterUserFactoryPage = Loadable({
  loader: () => import("./pages/registerPage/registerUserFactoryPage"),
  loading: Loading,
});

const LoadableMachinePage = Loadable({
  loader: () => import("./pages/machinePage/machinePage"),
  loading: Loading,
});

const LoadableManageMachinePage = Loadable({
  loader: () => import("./pages/machinePage/manageMachinePage"),
  loading: Loading,
});

const LoadableMachineSchedulePage = Loadable({
  loader: () => import("./pages/machineSchedulePage/machineSchedulePage"),
  loading: Loading,
});

const LoadableMachineGaugePage = Loadable({
  loader: () => import("./pages/machineGaugePage/machineGaugePage"),
  loading: Loading,
});

const LoadableManageMachineGaugePage = Loadable({
  loader: () => import("./pages/manageMachineGaugePage/manageMachineGaugePage"),
  loading: Loading,
});

const LoadableFactoryPage = Loadable({
  loader: () => import("./pages/factoryPage/factoryPage"),
  loading: Loading,
});

const LoadableManageFactoryPage = Loadable({
  loader: () => import("./pages/factoryPage/manageFactoryPage"),
  loading: Loading,
});

const LoadableNewOrganizationPage = Loadable({
  loader: () => import("./pages/newOrganizationPage/newOrganizationPage"),
  loading: Loading,
});

const LoadablSelectFactoryPage = Loadable({
  loader: () => import("./pages/selectFactoryPage/selectFactoryPage"),
  loading: Loading,
});

const LoadableUserProfilePage = Loadable({
  loader: () => import("./pages/userProfilePage/userProfilePage"),
  loading: Loading,
});

const LoadableUserHaveNoFactory = Loadable({
  loader: () => import("./pages/noFactoryPage/noFactoryPage"),
  loading: Loading,
});

const LoadableForgotPasswordPage = Loadable({
  loader: () => import("./pages/resetPasswordPage/forgotPasswordPage"),
  loading: Loading,
});

const LoadableResetPasswordPage = Loadable({
  loader: () => import("./pages/resetPasswordPage/resetPasswordPage"),
  loading: Loading,
});

const LoadableTermAndConditionPage = Loadable({
  loader: () => import("./pages/termAndConditionPage/termAndConditionPage"),
  loading: Loading,
});

const LoadablePrivacyPolicyPage = Loadable({
  loader: () => import("./pages/privacyPolicyPage/privacyPolicyPage"),
  loading: Loading,
});

const LoadableMobileAccessPage = Loadable({
  loader: () => import("./pages/mobileAccessPage/mobileAccessPage"),
  loading: Loading,
});

const LoadableSettingPage = Loadable({
  loader: () => import("./pages/settingPage/settingPage"),
  loading: Loading,
});

const LoadableConfirmDeleteUserPage = Loadable({
  loader: () => import("./pages/confirmDeleteUserPage/confirmDeleteUserPage"),
  loading: Loading,
});

const LoadableManageGaugePage = Loadable({
  loader: () => import("./pages/manageGaugePage/manageGaugePage"),
  loading: Loading,
});

const LoadableMachineDashboard = Loadable({
  loader: () => import("./pages/dashboardPage/dashboardPage"),
  loading: Loading,
});

const LoadableMachineReport = Loadable({
  loader: () => import("./pages/dashboardReportPage/dashboardReportPage"),
  loading: Loading,
});

const LoadableLog = Loadable({
  loader: () => import("./pages/logPage/logPage"),
  loading: Loading,
});

const LoadableFactoryFormPage = Loadable({
  loader: () => import("./pages/factoryPage/factoryFormPage"),
  loading: Loading,
});

const LoadableEmailNotMatchPage = Loadable({
  loader: () => import("./pages/emailNotMatchPage/emailNotMatchPage"),
  loading: Loading,
});

const LoadableFactoryMemberPage = Loadable({
  loader: () => import("./pages/factoryMemberPage/factoryMemberPage"),
  loading: Loading,
});

export const Home = LoadableHome;
export const Dashboard = LoadableDashboard;
export const ModalDialog = LoadableModal;
export const LoginPage = LoadableLogin;
export const Organization = LoadableOrganization;
export const RegisterPage = LoadableRegister;
export const RegisterEmailVerifyPage = LoadableRegisterEmailVerify;
export const FirebaseAuthLandingPage = LoadableFirebaseAuth;
export const RegisterUserFactoryPage = LoadableRegisterUserFactoryPage;
export const MachinePage = LoadableMachinePage;
export const ManageMachinePage = LoadableManageMachinePage;
export const MachineSchedulePage = LoadableMachineSchedulePage;
export const MachineGaugePage = LoadableMachineGaugePage;
export const ManageMachineGaugePage = LoadableManageMachineGaugePage;
export const FactoryPage = LoadableFactoryPage;
export const ManageFactoryPage = LoadableManageFactoryPage;
export const NewOrganizationPage = LoadableNewOrganizationPage;
export const SelectFactorynPage = LoadablSelectFactoryPage;
export const UserProfilePage = LoadableUserProfilePage;
export const NoFactoryPage = LoadableUserHaveNoFactory;
export const ForgotPasswordPage = LoadableForgotPasswordPage;
export const ResetPasswordPage = LoadableResetPasswordPage;
export const TermAndConditionPage = LoadableTermAndConditionPage;
export const MobileAccessPage = LoadableMobileAccessPage;
export const PrivacyPolicyPage = LoadablePrivacyPolicyPage;
export const SettingPage = LoadableSettingPage;
export const ConfirmDeleteUserPage = LoadableConfirmDeleteUserPage;
export const ManageGaugePage = LoadableManageGaugePage;
export const MachineDashboardPage = LoadableMachineDashboard;
export const MachineReportPage = LoadableMachineReport;
export const LogPage = LoadableLog;
export const FactoryFormPage = LoadableFactoryFormPage;
export const EmailNotMatchPage = LoadableEmailNotMatchPage;
export const FactoryMemberPage = LoadableFactoryMemberPage;
