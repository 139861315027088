import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Bell } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { NotificationInterface } from "../../Interfaces/notification";
import "../notificationDropdown/notificationDropdown.scss";
import client from "../../utils/axios";
import { toast } from "react-toastify";
import classNames from "classnames";
import _ from "lodash";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { DELAY_HIDE, DELAY_SHOW } from "../../constants/tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { ResponseList } from "../../Interfaces";
import { BsClockHistory, BsCreditCard, BsGear } from "react-icons/bs";
import { NotificationCode } from "../../constants/notification";
import dayjs from "dayjs";

const NotificationDropdown: React.FC = () => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState<number>(5);
  const [notificationList, setNotificationList] = useState<
    NotificationInterface[]
  >([]);

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const getNotification = () => {
    const query = {
      perPage: limit,
    };
    client()
      .get<ResponseList<NotificationInterface>>("v2/auth/notifications", {
        params: query,
      })
      .then((res) => {
        setNotificationList(res.data.data.items);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getReadNotification = (readId: number) => {
    client()
      .patch(`/v2/auth/notifications/${readId}/read`)
      .then((res) => {
        getNotification();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
  };

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
          }}
        >
          {props.children}
        </a>
      );
    }
  );

  const handleView = () => {
    const find = notificationList?.filter((item) => item.id);

    if (!_.isUndefined(find) && find?.length >= limit) {
      return (
        <span className="button-view" onClick={handleSummitView}>
          {t("VIEW_MORE")}
        </span>
      );
    } else {
      return <></>;
    }
  };

  const handleSummitView = () => {
    setLimit((prevState: number) => prevState + 5);
  };

  const changeNotification = (id: number): void => {
    const readId = id;
    getReadNotification(readId);
  };

  const checkNotification = () => {
    const find = notificationList?.filter((item) => item.read === false);

    if (!_.isUndefined(find) && find?.length > 0) {
      return <span className="notification-content"></span>;
    } else {
      return <></>;
    }
  };

  const handleReadNotificationAll = async () => {
    try {
      await client().put("v2/auth/notifications");
      getNotification();
    } catch (error: any) {
      toast.error(error?.response?.data?.error?.message);
    }
  };

  const renderNotificationBox = (
    icon: JSX.Element,
    titleClassName: string,
    title: string,
    detail: string,
    date: string
  ) => {
    return (
      <div className="notification-box">
        {icon}
        <div>
          <div className={`text-title ${titleClassName}`}>{title}</div>
          <div className="text-message">{detail}</div>
          <div className="text-date">{date}</div>
        </div>
      </div>
    );
  };

  const renderNotificationContent = (item: NotificationInterface) => {
    const formattedDate = item.createOn
      ? dayjs(item.createOn).format("DD-MM-YYYY, HH:mm")
      : "-";
    if (
      [
        NotificationCode.IncomingRecord,
        NotificationCode.Overrated,
        NotificationCode.Underrated,
        NotificationCode.Measurement,
        NotificationCode.Maintenance,
      ].includes(item.code as NotificationCode)
    ) {
      return renderNotificationBox(
        <BsGear className="notification-icon blue" />,
        "blue",
        item.title,
        item.detail,
        formattedDate
      );
    } else if (item.code === NotificationCode.PaymentNotice) {
      return renderNotificationBox(
        <BsCreditCard className="notification-icon yellow" />,
        "yellow",
        item.title,
        item.detail,
        formattedDate
      );
    } else {
      return renderNotificationBox(
        <BsClockHistory className="notification-icon pink" />,
        "pink",
        item.title,
        item.detail,
        formattedDate
      );
    }
  };

  return (
    <div
      className="dropdown-notification"
      id="tooltip-notification"
      data-tooltip-content={t("NOTIFICATION")}
    >
      <Dropdown
        onToggle={(isOpen) => {
          if (!isOpen) {
            handleReadNotificationAll();
          }
        }}
      >
        <Dropdown.Toggle as={CustomToggle}>
          <div className="round-icon me-2">
            <>
              <Bell className="" />
              {checkNotification()}
            </>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="menu-dropdown">
          <div>
            <span className="title-menu">{t("NOTIFICATION")}</span>
            <>
              {notificationList?.map((item) => {
                return (
                  <Dropdown.Item
                    key={item.id}
                    value={item.id}
                    className={classNames(
                      `${item.read ? "text-noti-read" : "text-noti-new"}`
                    )}
                    onClick={() => changeNotification(item.id)}
                  >
                    {renderNotificationContent(item)}
                  </Dropdown.Item>
                );
              })}
            </>
            <>{handleView()}</>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <ReactTooltip
        anchorId="tooltip-notification"
        delayShow={DELAY_SHOW}
        delayHide={DELAY_HIDE}
      />
    </div>
  );
};

export default NotificationDropdown;
