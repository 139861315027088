import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { PATH } from "./path";

// **** Just copy import from this repository in the link below. ****
// https://mui.com/material-ui/material-icons/
// ******************************************************************
// import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import { OverridableComponent } from "@mui/material/OverridableComponent";

// React-icons
// https://react-icons.github.io/react-icons
// import { GiOrganigram } from "react-icons/gi";
// import { VscUngroupByRefType } from "react-icons/vsc";
// import { BsDashLg } from "react-icons/bs";

// We can also add custom SVG here...
//import burgerMenu from "../assets/svg/hamburger_menu.svg";
import { IconType } from "react-icons";
import iconHouseDoorFilled from "../assets/svg/bs-house-door-fill.svg";
import iconRobotGrab from "../assets/svg/react-icon-robot-grab.svg";
import iconFactory from "../assets/svg/react-icon-factory.svg";
import iconClipboardData from "../assets/svg/bs-clipboard-data-fill.svg";

export interface ISidebarItem {
  path?: string;
  text: string;
  icon?: string | IconType | OverridableComponent<SvgIconTypeMap>;
  isgroup: boolean;
  group: ISidebarItem[];
}

export const SIDEBAR_ITEMLIST: ISidebarItem[] = [
  {
    path: PATH.DASHBOARD,
    text: "MENU_DASHBOARD",
    icon: iconHouseDoorFilled,
    isgroup: false,
    group: [],
  },
  {
    path: PATH.MACHINE,
    text: "MENU_MACHINE",
    icon: iconRobotGrab,
    isgroup: false,
    group: [],
  },
  {
    path: PATH.FACTORY,
    text: "MENU_FACTORY",
    icon: iconFactory,
    isgroup: false,
    group: [],
  },
  {
    path: PATH.LOG,
    text: "MENU_LOG",
    icon: iconClipboardData,
    isgroup: false,
    group: [],
  },
];
