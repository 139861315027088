import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Machine, MachineViewResponse } from "../Interfaces/machine";
import client from "../utils/axios";

import { matchPath, useLocation } from "react-router-dom";
import { IGlobalContext } from "../Interfaces/context";
import dayjs from "dayjs";
import { MachineTypeInterface } from "../Interfaces/dashboard";
import { PUBLIC_PATH } from "../constants/path";
import { DatePeriodStorageKey } from "../constants/datePicker";

export const GlobalContext = createContext<IGlobalContext>({
  loadMachineType: () => {},
  isMachineLoading: false,
  machineType: [],
  startDate: dayjs().startOf("day").toDate(),
  endDate: dayjs().endOf("day").toDate(),
  minDate: dayjs().subtract(1, "year").toDate(),
  maxDate: new Date(),
  setStartDateState: (date: Date) => {},
  setEndDateState: (date: Date) => {},
  selectedMachine: undefined,
  selectedMachineType: undefined,
  setSelectedMachineState: (selected: any) => {},
  setSelectedMachineTypeState: (selected: any) => {},
});

export interface GlobalProviderProps {
  children: any;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const location = useLocation();
  const pathname: string = location.pathname;
  const startOfDay = dayjs().subtract(7, "day").toDate();
  const endOfDay = dayjs().endOf("day").toDate();
  const minDate = dayjs().subtract(1, "year").toDate();
  const maxDate = new Date();

  const [startDate, setStartDate] = useState<Date>(startOfDay);
  const [endDate, setEndDate] = useState<Date>(endOfDay);
  const [isMachineLoading, setIsMachineLoading] = useState<boolean>(false);
  const [selectedMachine, setSelectedMachine] = useState<Machine>();
  const [machineType, setMachineType] = useState<any>();
  const [selectedMachineType, setSelectedMachineType] =
    useState<MachineTypeInterface>();

  const isPublicPage = (): boolean => {
    return PUBLIC_PATH.some((path) => {
      return !!matchPath({ path }, pathname);
    });
  };

  useEffect(() => {
    if (!isPublicPage()) {
      // loadMachines();
      loadMachineType();
    }

    initDateRage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initDateRage = () => {
    const startLocalStorage = localStorage.getItem(
      DatePeriodStorageKey.StartDate
    );
    const endDateLocalStorage = localStorage.getItem(
      DatePeriodStorageKey.EndDate
    );

    if (startLocalStorage) {
      setStartDate(new Date(startLocalStorage));
    }

    if (endDateLocalStorage) {
      setEndDate(new Date(endDateLocalStorage));
    }
  };

  const setSelectedMachineState = (selected: any): void => {
    setSelectedMachine(selected);
  };

  const setStartDateState = (date: Date): void => {
    localStorage.setItem(DatePeriodStorageKey.StartDate, date.toString());
    setStartDate(date);
  };

  const setEndDateState = (date: Date): void => {
    localStorage.setItem(DatePeriodStorageKey.EndDate, date.toString());
    setEndDate(date);
  };

  const setSelectedMachineTypeState = (selected: any): void => {
    setSelectedMachineType(selected);
  };

  const loadMachineType = () => {
    setIsMachineLoading(true);

    client()
      .get<MachineTypeInterface[]>(`/v1/master-data/machine-types`)
      .then((res) => {
        if (res.data) {
          setMachineType(res.data);
          setSelectedMachineType(res.data[1]);
        }
      })
      .catch((err) => {
        toast.error(err.toString());
      })
      .finally(() => {
        setIsMachineLoading(false);
      });
  };

  return (
    <GlobalContext.Provider
      value={{
        loadMachineType,
        isMachineLoading,
        startDate,
        endDate,
        setStartDateState,
        setEndDateState,
        selectedMachine,
        setSelectedMachineState,
        machineType,
        selectedMachineType,
        setSelectedMachineTypeState,
        minDate,
        maxDate,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
