import { useContext } from "react";
import { FactoryRole } from "../constants/role";
import { ProfileContext } from "../context/profileContext";

/**
 * Retrive profile from context
 */
const useProfile = () => {
  const { profile, loadProfile, loading } = useContext(ProfileContext);

  const getFactoryRole = (factoryId: number) => {
    if (profile && profile.organizations) {
      const factoryRole = profile.organizations.find((org) =>
        org.factories.map((factory) => factory.id === factoryId)
      );

      if (factoryRole) {
        return factoryRoleMapper(factoryRole.role.id);
      }
    }
    return FactoryRole.NONE;
  };

  const factoryRoleMapper = (factoryRoleId: number) => {
    switch (Number(factoryRoleId)) {
      case 1:
        return FactoryRole.SUPER_ADMIN;
      case 2:
        return FactoryRole.ADMIN;
      case 3:
        return FactoryRole.MEMBER;
      default:
        return FactoryRole.NONE;
    }
  };

  return {
    profile,
    getFactoryRole,
    factoryRoleMapper,
    loadProfile,
    loading,
  };
};

export default useProfile;
