import { Container, Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <Container className="d-flex justify-content-center align-items-center h-100">
      <Spinner animation="grow" size="sm" variant="primary" className="me-2" />
      <Spinner animation="grow" size="sm" variant="primary" className="me-2" />
      <Spinner animation="grow" size="sm" variant="primary" className="me-2" />
    </Container>
  );
};

export default Loading;
