import { createContext, FC, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import { PATH, PUBLIC_PATH } from "../constants/path";
import { Profile } from "../Interfaces/profile";
import client from "../utils/axios";
import { LoaderContext } from "./loaderContext";
import { ResponseItem } from "../Interfaces";
import { setCurrentLanguage } from "../utils/languageUtil";
import { languageType } from "../constants/language";
import { getUserToken } from "../utils/authUtil";

interface IProfileContext {
  profile?: Profile;
  loadProfile?: (disableLoadingScreen?: boolean) => void;
  loading?: boolean;
}

export const ProfileContext = createContext<IProfileContext>({});

export interface ProfileProviderProps {
  children: any;
}

export const ProfileProvider: FC<ProfileProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<Profile>();
  const { showLoading, closeLoading } = useContext(LoaderContext);

  const isPublicPage = () => {
    for (let i = 0; i < PUBLIC_PATH.length; i++) {
      let match: boolean = !!matchPath(
        {
          path: PUBLIC_PATH[i],
        },
        pathname
      );
      if (match) return match;
    }
    return false;
  };

  const loadProfile = (disableLoadingScreen: boolean = false) => {
    const user = getUserToken();

    if (isPublicPage()) {
      return;
    }
    // /**
    //  * Authroize with /profile
    //  */

    if (!disableLoadingScreen) {
      setLoading(true);
      showLoading();
    }

    client()
      .get<ResponseItem<Profile>>("/v2/auth/me")
      .then((response) => {
        const profile = response?.data?.data?.item;

        setProfile(profile);

        setCurrentLanguage(
          profile?.language?.id === languageType.TH ? "th" : "en"
        );

        if (!profile.organizations || profile.organizations.length === 0) {
          navigate(PATH.NEW_ORGANIZATION);
        } else if (!user?.selected_factory || !user?.selected_organization) {
          if (pathname !== PATH.SELECT_FACTORY) {
            navigate(PATH.SELECT_FACTORY);
          }
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          //TODO: Reflesh token flow
          navigate(PATH.LOGIN);
        }
      })
      .finally(() => {
        setLoading(false);
        closeLoading();
      });
  };

  useEffect(() => {
    loadProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        profile,
        loadProfile,
        loading,
      }}
    >
      {/* TODO: Loading page */}
      {loading && <></>}
      {!loading && children}
    </ProfileContext.Provider>
  );
};
