import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./localization/config";
import Toast from "./components/toast";
import Routes from "./routes";
import { FirebaseProvider } from "./utils/firebase";
import { useTranslation } from "react-i18next";

import "./scss/main.scss";
import "react-datepicker/dist/react-datepicker.css";
import { LoaderProvider } from "./context/loaderContext";
import { ProfileProvider } from "./context/profileContext";
import { GlobalProvider } from "./context/globalContext";
import { useState } from "react";

import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.extend(utc);

function App() {
  const { t } = useTranslation();
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  function showOfflineMessage() {
    setIsOffline(true);
  }

  function hideOfflineMessage() {
    setIsOffline(false);
  }

  let debounceTimeout: NodeJS.Timeout | undefined;

  function handleOfflineEvent() {
    clearTimeout(debounceTimeout); // Clear any existing debounce timeout

    showOfflineMessage();

    debounceTimeout = setTimeout(() => {
      hideOfflineMessage();
    }, 5000); // Debounce time of 5 seconds (5000 milliseconds)
  }

  window.addEventListener("offline", handleOfflineEvent);
  window.addEventListener("online", hideOfflineMessage);

  return (
    <FirebaseProvider>
      <div>
        <LoaderProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{"ABC Gauge reader"}</title>
            <meta
              name="description"
              content="ONE Solution. For your SMART Factories."
            />
            <meta property="og:title" content="ABC Gauge reader" />
            <meta
              property="og:description"
              content="ONE Solution. For your SMART Factories."
            />
            <meta
              property="og:image"
              content={window.location.origin + "/favicon.png"}
            />
          </Helmet>
          {/* We use BrowserRouter specifically because Router is on a higher-level and it will implicitly use one the implementations of Router by default anyway */}
          <div className="posotion-relative">
            {isOffline && (
              <div className="offline-message-wrap">
                <div className="offline-message">
                  {t("NO_INTERNET_CONNECTION")}
                </div>
              </div>
            )}

            <BrowserRouter>
              <GlobalProvider>
                <ProfileProvider>
                  <Routes />
                </ProfileProvider>
              </GlobalProvider>
            </BrowserRouter>
            <Toast />
          </div>
        </LoaderProvider>
        {/* <Loading show={true}></Loading> */}
      </div>
    </FirebaseProvider>
  );
}

export default App;
